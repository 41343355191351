import React from 'react';
import numeral from 'numeral';
import { BtnGroup } from '@capcenter/shared-legacy';
import { Colors } from '@capcenter/shared';

//const nFormat = '0,0';

export default class MonthlyPayment extends React.Component<{
    disabled: Boolean;
    quotes: Array<any>;
}> {

    monthlyPayment = (i: number, quotes: any) => {
        return (
          <span>
            <div style={{ marginTop: 15 }}>
                <span>${numeral((quotes as any)[i]?.MonthlyPayment?.Total?.CapcenterFee).format('0')}<small>/month</small></span>
            </div>
          </span>
        );
    };
  
  render() {
    const quotes = this.props.quotes;

    return (
        <>
        {this.props.disabled ? (
          ''
        ) : (
          <BtnGroup
            className={'rate-group'}
            color={Colors.orangeSecondary}
            invalid={false}
            style={{ border: 0, color: '#979797', padding: '0.375rem 0.375rem', marginTop: -20, marginBottom: -20, zIndex: 0, pointerEvents: 'none' }}
            btns={quotes.map((quote, index) =>
              (({
                id: `quote--monthly-payment-${index}`,
                className: 'btn-rate',
                children: this.monthlyPayment(index, quotes),
                disabledTooltip: '',
                active: false,
                onClick: () => {},
                disabled: false,
              }))
            )}
          />
        )}
      </>
    );
  }
}
